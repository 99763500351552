export const getCountry = () => {
  const allowedCountries = ['br', 'ar', 'ec', 'co', 'pe', 'cl', 'do'];
  // eslint-disable-next-line no-restricted-globals
  const currentCountry = location.hostname.toLowerCase().split('.').pop();

  return allowedCountries.includes(currentCountry) ? currentCountry : 'br';
};

export const getWithDotComByCountry = (country) => {
    const countryNotCom = ['cl'];

    return countryNotCom.indexOf(country) === -1
        ? '.com.'
        : '.';
};
